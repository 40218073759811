import React from 'react'

import { Text } from 'components/dataDisplay'

import ModuleResults from './components/ModuleResults/ModuleResults'


type ResultsViewProps = {
  className?: string
  total: number
  searchResults: SearchModal.SearchResult[]
}

const ResultsView: React.FC<ResultsViewProps> = (props) => {
  const { className, total, searchResults } = props

  return (
    <div className={className}>
      <Text
        className="opacity-48"
        message={`Всего найдено: ${total}`}
        size="t16-20"
        color="titanic"
      />
      {
        searchResults.map(({ title, items, count, loadMore }, index) => (
          <ModuleResults
            key={index}
            className="mt-48px"
            title={title}
            items={items}
            count={count}
            loadMore={loadMore}
          />
        ))
      }
    </div>
  )
}


export default ResultsView
