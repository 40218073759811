import React, { useCallback } from 'react'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { useHistory } from 'router'

import { Button } from 'components/inputs'
import { Modal } from 'components/feedback'
import { Icon, Text } from 'components/dataDisplay'


type SubmitFailedModalProps = {
  title?: string
  subTitle?: string
  text?: string
  textHtml?: boolean
  buttonTitle?: string
  redirectLink?: string
  callback?: () => void
}

const SubmitFailedModal: React.FC<SubmitFailedModalProps & ModalVisibilityProps> = (props) => {
  const { title = 'Ошибка при внесении изменений', subTitle, text, textHtml, buttonTitle,
    redirectLink, callback, closeModal } = props

  const history = useHistory()

  const handleModalClose = useCallback(() => {
    closeModal()

    if (typeof callback === 'function') {
      callback()
    }

    if (redirectLink) {
      history.push(redirectLink)
    }
  }, [ callback, closeModal, history, redirectLink ])

  return (
    <Modal
      title=""
      closeModal={closeModal}
    >
      <div className="flex flex-col justify-center">
        <Icon
          name="action/close_56"
          color="fargo"
        />
        <Text
          className="mt-32px"
          message={title}
          size="h32"
        />
        {
          subTitle && (
            <Text
              className="mt-16px"
              message={subTitle}
              size="h20-24"
            />
          )
        }
        {
          text && (
            <Text
              className="mt-16px opacity-48"
              message={text}
              size="t16-24"
              html={textHtml}
            />
          )
        }
        <Button
          className="mt-32px"
          title={buttonTitle || 'Повторить'}
          style="secondary"
          onClick={handleModalClose}
          fullWidth
          size={40}
        />
      </div>
    </Modal>
  )
}

export const openSubmitFailedModal = (props: SubmitFailedModalProps) => openModal('submitFailedModal', props)


export default modalVisibility('submitFailedModal', SubmitFailedModal)