import cx from 'classnames'
import { checkWebView } from 'helpers'
import React, { useEffect, useMemo } from 'react'
import { Form as FormType, useFieldState } from 'formular'

import { useCityConfig } from 'models/city'

import { SocialIcon } from 'components/dataDisplay'
import { Text } from 'components/dataDisplay'
import { Input, Button, ActionButton, Form } from 'components/inputs'

import useLoginSocial from '../../util/useLoginSocial'

import { defaultSocials } from './settings'


type EnterCredsProps = {
  className?: string
  title?: string
  text?: string
  form: FormType<{
    email: string
    password: string
  }>
  isLoginSubmitting: boolean
  handleRegistrationClick: (event) => void
  handlePasswordRecoveryClick: (event) => void
  onError: (error: string) => void
  onSuccess: () => void
  onSubmit: (event: any) => Promise<void>
}

const EnterCreds: React.FC<EnterCredsProps> = (props) => {
  const { className, title, text, form, isLoginSubmitting, onSubmit,
    handleRegistrationClick, handlePasswordRecoveryClick, onError, onSuccess } = props

  const { isWebView, isIos, isAndroid } = checkWebView(navigator.userAgent)

  const { cityConfig, isCityConfigFetching } = useCityConfig()

  const currentCitySocials = useMemo(() => {
    const citySocials = cityConfig?.designOptions?.socials || defaultSocials
    const isWithAppleAuth = (isIos || !isWebView) && !isAndroid

    return isWithAppleAuth
      ? citySocials
      : citySocials.filter((social) => social !== '40/Apple_40')
  }, [ cityConfig?.designOptions?.socials, isAndroid, isIos, isWebView ])

  const { loginSocial } = useLoginSocial({ onError, onSuccess })

  useEffect(() => {
    const input = document.getElementById('username')

    if (input) {
      input.click()
    }
  }, [])

  const { error: passwordError } = useFieldState(form.fields.password)

  return (
    <Form className={className} id="login" onSubmit={onSubmit} disabled={isLoginSubmitting}>
      <Text
        message={title || `Войдите или зарегистрируйтесь`}
        size="h24"
      />
      <Text
        className="mt-8px opacity-72"
        message={text || `Введите свои учетные данные`}
        size="t16-24"
      />
      <Input
        className="mt-24px"
        field={form.fields.email}
        label="Логин"
        icon="main/avatar_16"
        iconPosition="left"
        type="text"
        name="username"
        id="username"
        autoComplete="username"
        // autoFocus
      />
      <Input
        className="mt-24px"
        field={form.fields.password}
        label="Пароль"
        icon="action/lock_16"
        iconPosition="left"
        type="password"
        autoComplete="current-password"
        // autoFocus
      />
      <Button
        className="mt-24px"
        id="login-button"
        title="Войти"
        style="primary"
        size={40}
        fullWidth
        type="submit"
        loading={isLoginSubmitting}
      />
      <div className="flex justify-between mt-24px">
        <ActionButton
          title="Регистрация"
          icon="main/avatar_16"
          color="rocky"
          onClick={handleRegistrationClick}
        />
        {
          passwordError && (
            <ActionButton
              title="Восстановить пароль"
              icon="action/restore_16"
              color="rocky"
              onClick={handlePasswordRecoveryClick}
            />
          )
        }
      </div>
      {
        Boolean(currentCitySocials?.length) && (
          <>
            <Text
              className="mt-32px opacity-48 text-center"
              message="войти с помощью"
              size="t16-20"
              color="titanic"
            />
            <div className="flex mt-16px justify-center flex-wrap" style={{ rowGap: '16px' }}>
              {
                currentCitySocials.map((name, index) => (
                  <SocialIcon
                    key={index}
                    className={cx({
                      'ml-16px': index,
                    })}
                    name={name}
                    onClick={() => loginSocial(name)}
                  />
                ))
              }
            </div>
          </>
        )
      }
    </Form>
  )
}

export default EnterCreds