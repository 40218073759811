import React, { useMemo } from 'react'
import { Field } from 'formular'
import links from 'links'

import { Text } from 'components/dataDisplay'
import { ArrowButton } from 'components/inputs'
import localStorage from 'local-storage'
import { Href } from 'components/navigation'


type PrevSearchProps = {
  className?: string
  field: Field<string>
  prevSearchItems: string[]
  handleSearch: (search: string) => void
}

const PrevSearch: React.FC<PrevSearchProps> = (props) => {
  const { className, field, prevSearchItems, handleSearch } = props

  return Boolean(prevSearchItems.length) && (
    <div className={className}>
      <Text
        className="opacity-48"
        message="Ранее вы искали"
        size="s13-r"
        color="titanic"
      />
      <div className="flex flex-wrap pt-16px" style={{ margin: '-8px' }}>
        {
          prevSearchItems.map((title, index) => (
            <Href
              key={index}
              className="m-8px opacity-72 pointer"
              onClick={() => {
                field.set(title)
                handleSearch(title)
              }}
            >
              <Text
                size="c16"
                message={title}
                color="rocky"
              />
            </Href>
          ))
        }
      </div>
    </div>
  )
}


export default PrevSearch
