import { useMemo, useRef } from 'react'
import { useQuery } from 'hooks'
import dayjs from 'date'
import links, { getLinkWithParams } from 'links'

import { getImage } from 'models/mapLayer'
import { useCategories } from 'models/appeals'
import { useDevice } from 'device'


const useCarouselAppeals = () => {
  const { isTablet } = useDevice()
  const { categories: categoriesList, isCategoriesFetching: isFetching } = useCategories()

  const categoriesListRef = useRef(categoriesList)
  categoriesListRef.current = categoriesList

  const queryOptions = useMemo(() => {
    // const status = selectedTabId === 'subscribed' ? 'mine' : selectedTabId

    return ({
      url: `/ajax.php?action=getCarousel&last=3&photoFile=1`,
      cacheTime: 5 * 60 * 1000,
      modifyResult: ({ all }: AdminAppealsHomePage.ApiData) => {
        // TODO handle error
        const list = []

        if (!all) {
          return {
            list,
          }
        }

        all.forEach((appeal) => {
          const { id, author, family, description, address, lat, lng, created, avatara, type, delay, photo } = appeal

          // const images = photos?.map(({ url }) => getImage('/' + url)) || []
          // const [ image ] = images

          list.push({
            id,
            title: description,
            // title: `№${id} ${description}`,
            date: {
              text: dayjs(created).format('DD MMMM, HH:mm:ss'),
              delayed: Boolean(Number(delay)),
            },
            author: family ? `${family} ${author}` : author,
            image: photo ? getImage(photo) : '',
            authorImage: avatara ? getImage(avatara) : '',
            address,
            placemark: [ Number(lat), Number(lng) ],
            categoryId: Number(type),
            type,
            to: getLinkWithParams(links.appeals.item.root, { itemId: id }),
          })
        })

        return {
          list,
        }
      },
    })
  }, [])

  const { data, isFetching: isCarouselListFetching } = useQuery(queryOptions)

  const carouselList = useMemo(() => {
    if (data?.list && categoriesList) {
      const list = data.list.map((appeal) => ({
        ...appeal,
        // TODO replace with status?
        type: categoriesList.find(({ value }) => value === Number(appeal.type))?.title || '',
      }))

      if (isTablet) {
        return list.filter((item, index) => index < 2)
      }

      return list
    }

    return []
  }, [ data, categoriesList, isTablet ])
  // console.log({ data })
  return {
    carouselList,
    isCarouselListFetching: isFetching || isCarouselListFetching,
  }
}


export default useCarouselAppeals
