import config from 'config'
import { usePathname } from 'router'
import React, { useMemo } from 'react'

import { useRouteData } from 'models/seo'
import { useCityConfig } from 'models/city'
import { useModuleTitle } from 'models/settings'

import { Meta } from 'components/dataDisplay'


declare global {
  interface Window {
    moduleTitle: string
  }
}

const GlobalMeta: React.FC = () => {
  const { platformTitle } = useCityConfig()

  const pathname = usePathname()

  const routeData = useRouteData()
  const { tag, title: noTagRouteTitle } = routeData

  const { cityName, storage } = config

  const { title: moduleTitle } = useModuleTitle(tag)

  const pageTitle = useMemo(() => {
    if (!tag && !noTagRouteTitle) {
      console.error('No tag or title in links for', pathname)
    }

    const title = moduleTitle || noTagRouteTitle
    if (!__SERVER__) {
      window.moduleTitle = title // vue modules take title from here
    }

    // TODO prepare cached city name
    if (cityName) {
      return `${title || '404'} | ${platformTitle}`
    }

    return `${title || '404'}`
  }, [ cityName, moduleTitle, noTagRouteTitle, pathname, platformTitle, tag ])

  return (
    <Meta
      title={pageTitle}
      image=""
      pathname={pathname}
      storage={storage}
    />
  )
}


export default GlobalMeta
