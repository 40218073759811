import React from 'react'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'

import type { NotificationProps } from 'components/feedback'


const ServerErrorNotification: React.FC<NotificationProps> = ({ closeNotification }) => (
  <PlainNotification
    title="Что-то пошло не так"
    text={`
      Попробуйте обновить страницу 
    `}
    closeNotification={closeNotification}
  />
)


export default ServerErrorNotification
