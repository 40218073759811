import React, { useEffect } from 'react'
import { usePathname } from 'router'
import { closeModal, modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Bone } from 'components/layout'
import { Modal } from 'components/feedback'

import EmptyView from './components/EmptyView/EmptyView'
import InitialView from './components/InitialView/InitialView'
import SearchInput from './components/SearchInput/SearchInput'
import ResultsView from './components/ResultsView/ResultsView'

import { useSearch } from './util'


const SearchModal: React.FC<ModalVisibilityProps> = (props) => {
  const { closeModal } = props

  const pathname = usePathname()

  useEffect(() => {
    return () => {
      closeModal()
    }
  }, [ pathname, closeModal ])

  const {
    form, total, view, searchResults, prevSearchItems,
    handleSearch, handleButtonClick,
  } = useSearch()

  return (
    <Modal
      className="justify-start"
      title="Поиск по сайту"
      width={618}
      fullWidth
      closeModal={closeModal}
    >
      <div className="flex flex-col justify-center h-full">
        <SearchInput
          field={form.fields.search}
          onButtonClick={handleButtonClick}
        />
        {
          view === 'initial' && (
            <InitialView
              className="mt-32px"
              field={form.fields.search}
              prevSearchItems={prevSearchItems}
              handleSearch={handleSearch}
            />
          )
        }
        {
          view === 'fetching' && (
            <Bone
              className="mt-24px"
              textSize="t16-20"
              w={140}
            />
          )
        }
        {
          view === 'fetched' && (
            <ResultsView
              className="mt-24px"
              total={total}
              searchResults={searchResults}
            />
          )
        }
        {
          view === 'empty' && (
            <EmptyView
              className="mt-72px"
              closeModal={closeModal}
            />
          )
        }
      </div>
    </Modal>
  )
}


export const closeSearchModal = () => closeModal('SearchModal')

export const openSearchModal = () => openModal('SearchModal')

export default modalVisibility('SearchModal', SearchModal)
