import React from 'react'

import Modals from 'containers/App/Modals/Modals'
import GlobalMeta from 'containers/App/GlobalMeta/GlobalMeta'
import Notifications from 'containers/App/Notifications/Notifications'


const CommonData: React.FC = () => (
  <>
    <Modals />
    <Notifications />
    <GlobalMeta />
  </>
)


export default CommonData
