import { useCallback } from 'react'
import { useMutation } from 'hooks'


type ApiData = {
  all: SearchModal.SearchData[]
}

const usePhpSearch = () => {
  const [ submit ] = useMutation<ApiData>({
    url: '/ajax.php?action=globalSearch&unified=1&civil=1',
  })

  const searchPhp = useCallback((search: string) => {
    return submit({
      q: search,
    })
      .then(({ data }) => {
        if (data.all) {
          return data.all
        }

        return []
      })
  }, [ submit ])

  const loadMorePhp = useCallback(({ search, layerId }: { search: string, layerId?: number }) => {
    return submit({
      q: search,
      // onLayers: layerId,
    })
      .then(({ data }) => data.all.find(({ id }) => id === layerId))
  }, [ submit ])

  return {
    searchPhp,
    loadMorePhp,
  }
}


export default usePhpSearch
