import { useQuery } from 'hooks'
import { useMemo } from 'react'


const useCityFastOptions = (props?) => {
  const { queryParam, skip }: { queryParam?: string, skip?: boolean} = props || {}

  const options = useMemo(() => ({
    url: `/ajax.php?action=getCityFastOptions${queryParam ? '&' + queryParam : ''}`,
    skip,
  }), [ skip, queryParam ])

  const { data, isFetching, errors } = useQuery<Settings.CityFastOptions>(options)

  return {
    cityFastOptions: data || {} as Settings.CityFastOptions,
    isCityFastOptionsFetching: isFetching,
    cityFastOptionsErrors: errors,
  }
}

export default useCityFastOptions