import { LicenseApiData } from 'models/city'


const getModuleTitle = (modules: LicenseApiData, tag: UserModel.ModuleTag) => {
  const module = modules?.find(({ tag: moduleTag }) => tag === moduleTag)
  const { title, customSettings } = module || {}
  const { title: customTitle } = customSettings || {}

  return module?.customTitle || customTitle || title
}

export default getModuleTitle