import React, { useCallback, useState } from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'
import { ArrowButton } from 'components/inputs'
import { Image, Text } from 'components/dataDisplay'

import { closeSearchModal } from '../../../../SearchModal'


type ModuleResultsProps = SearchModal.SearchResult & {
  className?: string
}

const ModuleResults: React.FC<ModuleResultsProps> = (props) => {
  const { className, title: moduleTitle, items, count, loadMore } = props

  const [ { isFetching, isExpanded, fetchedItems }, setState ] = useState({
    isFetching: false,
    isExpanded: false,
    fetchedItems: [],
  })

  const itemsList = isExpanded ? fetchedItems : items

  const handleLoadMore = useCallback(() => {
    if (fetchedItems?.length) {
      setState((state) => ({ ...state, isExpanded: true }))
    }
    else {
      setState((state) => ({ ...state, isFetching: true }))

      loadMore()
        .then((fetchedItems) => {
          if (fetchedItems) {
            setState({ isFetching: false, isExpanded: true, fetchedItems })
          }
          else {
            setState((state) => ({ ...state, isFetching: false }))
          }
        })
        .catch(() => {
          setState((state) => ({ ...state, isFetching: false }))
        })
    }
  }, [ fetchedItems, loadMore ])

  const expand = useCallback(() => {
    if (isExpanded) {
      setState((state) => ({
        ...state,
        isExpanded: false,
      }))
    }
    else {
      handleLoadMore()
    }
  }, [ isExpanded, handleLoadMore ])

  return (
    <div className={className}>
      <Text
        message={moduleTitle}
        size="h24"
      />
      <div className="mt-20px">
        {
          itemsList.map(({ to, title, address, image, date }, index) => (
            <Href
              key={index}
              className={cx('flex items-start pointer', index ? 'mt-16px' : null)}
              to={to}
              onClick={closeSearchModal}
            >
              {
                Boolean(image) && (
                  <Image
                    className="mr-12px"
                    src={image}
                    width="72"
                  />
                )
              }
              <div>
                <Text
                  message={title || '&nbsp;'}
                  size="t16-20"
                  color="titanic"
                  html
                />
                <Text
                  className="mt-8px opacity-48"
                  message={address || date}
                  size="s13-r"
                  color="titanic"
                />
              </div>
            </Href>
          ))
        }
      </div>
      {
        (count > 2) && (
          <ArrowButton
            className="mt-20px"
            title={isExpanded ? 'Скрыть' : `Показать ещё ${count - 2}`}
            color="rocky"
            disabled={isFetching}
            arrow={isExpanded ? 'up' : 'down'}
            position="right"
            onClick={expand}
          />
        )
      }
    </div>
  )
}


export default ModuleResults
