import { useMemo } from 'react'
import { useQuery } from 'hooks'
import config from 'config'


const useCityConfig = (skip?: boolean) => {
  const queryCity = useMemo(() => ({
    url: '/nginxApi.php?get=city',
    skip,
  }), [ skip ])

  const { data: cityData, isFetching: isCityDataFetching } = useQuery<City.PhpCityData>(queryCity)

  const platformTitle = useMemo(() => {
    return cityData?.title || `Умный<br />${config.cityName || 'Пуровский район'}`
  }, [ cityData?.title ])

  return {
    cityConfig: cityData || {} as City.PhpCityData,
    platformTitle,
    isCityConfigFetching: isCityDataFetching,
  }
}


export default useCityConfig
