import React from 'react'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'

import type { NotificationProps } from 'components/feedback'


type ErrorNotificationProps = NotificationProps & {
  title?: string
  text?: string
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ text, title, closeNotification }) => (
  <PlainNotification
    title={title || 'Ошибка'}
    text={text}
    icon="action/close_20"
    closeNotification={closeNotification}
  />
)


export default ErrorNotification
