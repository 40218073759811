import React from 'react'
import { Field, useFieldState } from 'formular'

import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'


type RegistrationSuccessProps = {
  className?: string
  emailField: Field<string>
  closeModal: () => void
}

const RegistrationSuccess: React.FC<RegistrationSuccessProps> = (props) => {
  const { className, emailField, closeModal } = props

  const { value: email } = useFieldState<string>(emailField)

  return (
    <>
      <Text
        message="Подтвердите email, чтобы завершить регистрацию"
        size="h24"
      />
      <Text
        className="mt-12px"
        message={email}
        size="h20-24"
      />
      <Text
        className="mt-8px opacity-72"
        message="Мы отправили вам письмо. Откройте его и перейдите по ссылке."
        size="t16-24"
      />
      <Button
        className="mt-24px"
        title="Понятно"
        icon="main/avatar_16"
        size={40}
        onClick={() => closeModal()}
        fullWidth
        style="secondary"
      />
    </>
  )
}

export default RegistrationSuccess