import { useMemo } from 'react'
import { usePathname } from 'router'
import { useAccesses } from 'models/user'
import { usePublicModules } from 'models/city'

import { getAdminModuleTitle, getModuleTitle } from '.'


const useModuleTitle = (tag: UserModel.ModuleTag) => {
  const pathname = usePathname()
  const isAdmin = useMemo(() => /^\/admin/.test(pathname), [ pathname ])

  const { accesses, isAccessesFetching } = useAccesses(!isAdmin)
  const { modules, isFetching: isPublicModulesFetching } = usePublicModules(isAdmin)

  const title = isAdmin ? getAdminModuleTitle(accesses, tag) : getModuleTitle(modules, tag)

  return {
    title,
    isFetching: isAccessesFetching || isPublicModulesFetching,
  }
}

export default useModuleTitle