import { useHistory } from 'router'
import cacheStorage from 'cache-storage'
import React, { useEffect } from 'react'

import { Icon, Text } from 'components/dataDisplay'


type LoginSuccessProps = {
  redirectLink: string
  closeLoginModal: () => void
  callback?: () => void
}

const LoginSuccess: React.FC<LoginSuccessProps> = (props) => {
  const { redirectLink, callback, closeLoginModal } = props

  const history = useHistory()

  useEffect(() => {
    if (typeof callback === 'function') {
      callback()
    }
  }, [ callback ])

  useEffect(() => {
    setTimeout(() => {
      closeLoginModal()
      window.dispatchEvent(new CustomEvent('login'))
      cacheStorage.reset('/auth/rest/')
      cacheStorage.reset('/auth/rest/user')
      cacheStorage.reset('/auth/rest/access')
      cacheStorage.reset('/auth/rest/accesses')
      cacheStorage.reset('/nginxApi.php?get=user')

      if (redirectLink) {
        history.push(redirectLink)
      }
    }, 1500)
  }, [ history, redirectLink, closeLoginModal ])

  return (
    <>
      <Icon
        name="action/selected_32"
        color="rocky"
      />
      <Text
        className="mt-24px"
        message="Вы успешно вошли"
        size="h24"
      />
      <Text
        className="mt-8px opacity-72"
        message="Рады снова вас видеть"
        size="t16-24"
      />
    </>
  )
}

export default LoginSuccess