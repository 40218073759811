import React, { useMemo } from 'react'
import links, { routeData } from 'links'

import { usePublicModules } from 'models/city'

import { Text } from 'components/dataDisplay'
import { ArrowButton } from 'components/inputs'


type QuickLinksProps = {
  className?: string
}

const QuickLinks: React.FC<QuickLinksProps> = (props) => {
  const { className } = props

  const { availableModuleTags } = usePublicModules()

  const quickLinks = useMemo(() => {
    return [
      {
        title: 'Расписание транспорта',
        to: links.transport.root,
      },
      {
        title: 'Обращения жителей',
        to: links.appeals.root,
      },
      {
        title: 'Бронирование и оплата',
        to: links.sport.root,
      },
      {
        title: 'Полиция',
        to: links.police.root,
      },
      {
        title: 'Медицина',
        to: links.medicine.root,
      },
      {
        title: 'Учреждения на карте',
        to: links.institutions.root,
      },
      {
        title: 'Депутатские округа',
        to: links.votings.root,
      },
    ]
      .filter(({ to }) => {
        const { tag } = routeData[to]

        return availableModuleTags.includes(tag)
      })
  }, [ availableModuleTags ])

  return (
    <div className={className}>
      <Text
        className="opacity-48"
        message="Быстрые ссылки"
        size="s13-r"
        color="titanic"
      />
      <div className="flex flex-wrap pt-16px" style={{ margin: '-8px' }}>
        {
          quickLinks.map(({ title, to }, index) => (
            <ArrowButton
              key={index}
              className="m-8px"
              title={title}
              to={to}
              color="rocky"
              arrow="right"
              position="right"
            />
          ))
        }
      </div>
    </div>
  )
}


export default QuickLinks
