import React from 'react'
import { Field } from 'formular'

import PrevSearch from '../PrevSearch/PrevSearch'
import QuickLinks from '../QuickLinks/QuickLinks'


type InitialViewProps = {
  className?: string
  field: Field<string>
  prevSearchItems: string[]
  handleSearch: (search: string) => void
}

const InitialView: React.FC<InitialViewProps> = (props) => {
  const { className, field, prevSearchItems, handleSearch } = props

  return (
    <div className={className}>
      <PrevSearch
        field={field}
        prevSearchItems={prevSearchItems}
        handleSearch={handleSearch}
      />
      <QuickLinks
        className="mt-32px"
      />
    </div>
  )
}


export default InitialView
