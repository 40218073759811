import { useMemo } from 'react'
import { usePublicModules } from 'models/city'


const usePurchasedModules = () => {
  const { modules, isFetching } = usePublicModules()

  const purchasedModuleTags = useMemo<UserModel.PublicModuleTag[]>(() => {
    if (modules) {
      return modules
        .filter(({ active }) => active)
        .map(({ tag }) => tag)
    }

    return []
  }, [ modules ])

  return {
    purchasedModuleTags,
    isPurchasedModuleTagsFetching: isFetching,
  }
}


export default usePurchasedModules
