import React from 'react'
import { useField, useFieldState } from 'formular'
import { email as isEmail, required } from 'helpers/validators'

import { useUser } from 'models/user'

import { Text } from 'components/dataDisplay'
import { Input, Button } from 'components/inputs'


type RecoverPasswordProps = {
  className?: string
  handleRecoverPasswordSubmit: (email: string) => void
}

const RecoverPassword: React.FC<RecoverPasswordProps> = (props) => {
  const { handleRecoverPasswordSubmit } = props

  const { hasAuth, user, isUserFetching } = useUser()

  const email = useField<string>({
    value: hasAuth ? user.email : '',
    validate: [ required, isEmail ],
  }, [ isUserFetching ])

  const { isValid } = useFieldState(email)

  return (
    <>
      <Text
        message="Введите свой email для сброса пароля"
        size="h24"
      />
      <Input
        className="mt-24px"
        field={email}
        label="Email"
        icon="communication/mail_16"
        iconPosition="left"
        autoComplete="none"
      />
      <Button
        className="mt-24px"
        title="Сбросить пароль"
        style="primary"
        size={40}
        fullWidth
        type="submit"
        onClick={() => handleRecoverPasswordSubmit(email.state.value)}
        disabled={!isValid}
      />
    </>
  )
}

export default RecoverPassword