import React from 'react'
import { Notifications as NotificationsConductor } from 'notifications'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'
import ErrorNotification from 'compositions/notifications/ErrorNotification/ErrorNotification'
import ServerErrorNotification from 'compositions/notifications/ServerErrorNotification/ServerErrorNotification'

import s from './Notifications.scss'


export const templates = {
  'plain': PlainNotification,
  'error': ErrorNotification,
  'serverError': ServerErrorNotification,
} as const

const Notifications = () => (
  <NotificationsConductor
    className={s.notifications}
    templates={templates}
  />
)


export default Notifications
