import React from 'react'
import { modalVisibility, openModal, closeModal } from 'modal'

import { Modal } from 'components/feedback'


export const openCommonModal = (props: CommonModal.Props) => openModal('common', props)

export const closeCommonModal = () => closeModal('common')


export default modalVisibility('common', Modal)
