import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Field, FieldState, useFieldState } from 'formular'

import { useCheckPassword, usePasswordPolicies } from 'models/user'

import { Text } from 'components/dataDisplay'
import { Input, Button } from 'components/inputs'


type RegistrationProps = {
  className?: string
  newNameField: Field<string>
  newEmailField: Field<string>
  newPhoneField: Field<string>
  newPasswordField: Field<string>
  newPasswordRepeatField: Field<string>
  handleRegistrationSubmit: () => void
}

const Registration: React.FC<RegistrationProps> = (props) => {
  const { newNameField, newEmailField, newPasswordField, newPasswordRepeatField,
    newPhoneField, handleRegistrationSubmit,
  } = props

  const { policies } = usePasswordPolicies()
  const { handleCheckPassword } = useCheckPassword({
    loginField: newNameField,
    emailField: newEmailField,
    passwordField: newPasswordField,
  })

  const { isValid: isNameValid, isChanged: isNameChanged } = useFieldState(newNameField)
  const { isValid: isEmailValid, isChanged: isEmailChanged } = useFieldState(newEmailField)
  const { isValid: isPasswordValid, isChanged: isPasswordChanged } = useFieldState(newPasswordField)
  const { isValid: isPhoneValid } = useFieldState(newPhoneField)
  const { isValid: isPasswordRepeatValid, isChanged: isPasswordRepeatChanged } = useFieldState(newPasswordRepeatField)
  const isValid = isEmailValid && isPasswordValid && isPasswordRepeatValid && isNameValid && isPhoneValid
  const isChanged = isNameChanged && isEmailChanged && isPasswordChanged && isPasswordRepeatChanged

  const [ showPassword, setShowPassword ] = useState(false)
  const [ showPassword2, setShowPassword2 ] = useState(false)

  useEffect(() => {
    if (!isEmailValid) {
      const resetError = () => newEmailField.setError(null)

      newEmailField.on('change', resetError)

      return () => {
        newEmailField.off('change', resetError)
      }
    }
  }, [ isEmailValid, newEmailField ])

  return (
    <>
      <Text
        message="Введите свои данные"
        size="h24"
      />
      <form id="registration">
        <Input
          className="mt-24px"
          field={newNameField}
          label="Имя"
          icon="main/avatar_16"
          iconPosition="left"
          autoComplete="none"
        />
        <Input
          className="mt-24px"
          field={newEmailField}
          label="Email"
          icon="communication/mail_16"
          iconPosition="left"
          autoComplete="none"
        />
        <Input
          className="mt-24px"
          field={newPhoneField}
          label="Телефон"
          mask="phone"
          icon="communication/telephone_16"
          iconPosition="left"
          autoComplete="none"
        />
        <Input
          className="mt-24px"
          field={newPasswordField}
          label="Пароль"
          type={showPassword ? 'text' : 'password'}
          icon="main/eye_16"
          iconPosition="right"
          onIconClick={() => setShowPassword(!showPassword)}
          iconTooltip={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
          autoComplete="new-password"
          onBlur={handleCheckPassword}
        />
        {
          Boolean(policies) && (
            <FieldState field={newPasswordField}>
              {
                ({ error }) => (
                  <div
                    className={cx('radius-16 px-20px py-12px mb-16px bg-warrior', {
                      'mt-24px': error,
                      'mt-12px': !error,
                    })}
                    style={{ border: '1px solid #FBECDD' }}
                  >
                    <Text
                      className="opacity-48 mt-4px"
                      message={policies}
                      size="s13-r"
                      color="schindler"
                      html
                    />
                  </div>
                )
              }
            </FieldState>
          )
        }
        <Input
          className="mt-24px"
          field={newPasswordRepeatField}
          label="Пароль повторно"
          type={showPassword2 ? 'text' : 'password'}
          icon="main/eye_16"
          iconPosition="right"
          onIconClick={() => setShowPassword2(!showPassword2)}
          iconTooltip={showPassword2 ? 'Скрыть пароль' : 'Показать пароль'}
          autoComplete="new-password"
        />
        <Button
          className="mt-24px"
          title="Продолжить"
          style="primary"
          size={40}
          fullWidth
          type="submit"
          onClick={handleRegistrationSubmit}
          disabled={!isChanged || !isValid}
        />
      </form>
    </>
  )
}

export default Registration