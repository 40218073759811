import { useForm } from 'formular'
import localStorage from 'local-storage'
import links, { routeData } from 'links'
import { usePurchasedModules } from 'models/city'
import { useCallback, useMemo, useState } from 'react'

import useApiSearch from './useApiSearch'
import usePhpSearch from './usePhpSearch'
import modifySearchItems from './modifySearchItems'
import useServiceAreaSearch from './useServiceAreaSearch'


const resultsOrder: {
  to?: string
  itemLink?: string
  title: string
  tag: string
}[] = [
  {
    // Транспорт
    to: links.transport.route.details,
    ...routeData[links.transport.root],
  },
  {
    // Обращения
    itemLink: links.appeals.item.root,
    ...routeData[links.appeals.root],
  },
  {
    // Сервисы и службы - Полиция
    itemLink: links.police.item,
    ...routeData[links.police.root],
  },
  {
    // Сервисы и службы - Округа
    itemLink: links.votings.item,
    ...routeData[links.votings.item],
  },
  {
    // Сервисы и службы - Округа
    itemLink: links.elections.item,
    ...routeData[links.elections.item],
  },
  {
    // Сервисы и службы - Учреждения
    itemLink: links.institutions.item,
    ...routeData[links.institutions.root],
  },
  {
    // Сервисы и службы - Медицина
    itemLink: links.medicine.item,
    ...routeData[links.medicine.root],
  },
  {
    // Сервисы и службы - Достопримечательности
    itemLink: links.culture.item,
    ...routeData[links.culture.root],
  },
  // Товары и услуги
  {
    itemLink:  links.votings.item,
    ...routeData[links.votings.root],
  },
  {
    // Мероприятия
    itemLink: links.events.item,
    ...routeData[links.events.root],
  },
  // Спецпроекты
  {
    itemLink: links.projects.item,
    ...routeData[links.projects.root],
  },
  {
    // Новости
    itemLink: links.news.item,
    ...routeData[links.news.root],
  },
  // Бизнесу
  {
    itemLink: links.goodsAndServices.item,
    ...routeData[links.goodsAndServices.root],
  },
  // ГИС ЖКХ
  {
    itemLink: links.serviceArea.item,
    ...routeData[links.serviceArea.root],
  },
]

const cache = {}

const useSearch = () => {
  const form = useForm<{ search: string }>({
    fields: {
      search: {
        value: '',
      },
    },
  })

  const prevSearchItems = useMemo(() => {
    const storageSearchItems = localStorage.getItem('searchItems') || []

    if (Array.isArray(storageSearchItems)) {
      return storageSearchItems.filter((item, index) => storageSearchItems.indexOf(item) === index)
    }

    return []
  }, [])

  const [ { view, searchResults }, setState ] = useState<{
    view: string
    searchResults: SearchModal.SearchResult[]
  }>({
    view: 'initial',
    searchResults: [],
  })

  const { searchPhp, loadMorePhp } = usePhpSearch()
  const { searchApi, loadMoreApi } = useApiSearch()
  const { searchServiceArea, loadMoreServiceArea } = useServiceAreaSearch()

  const { purchasedModuleTags } = usePurchasedModules()
  const withAPI = (
    purchasedModuleTags?.includes('NEWS')
    || purchasedModuleTags?.includes('VOTING')
    || purchasedModuleTags?.includes('POSTER')
    || purchasedModuleTags?.includes('CITY_PROJECTS')
  )

  const handleSearch = useCallback((search: string) => {
    const cachedData = cache[search]

    setState({ view: 'fetching', searchResults: [] })

    if (cachedData) {
      setTimeout(() => {
        if (cachedData.length) {
          setState({ view: 'fetched', searchResults: cachedData })
        }
        else {
          setState({ view: 'empty', searchResults: [] })
        }
      })

      return
    }

    Promise.all([
      withAPI ? searchApi(search) : Promise.resolve([]),
      searchPhp(search),
      searchServiceArea(search),
    ])
      .then(([ apiSearchResult, phpSearchResult, serviceAreaSearchResult ]) => {
        console.log({ apiSearchResult, phpSearchResult, serviceAreaSearchResult })
        const searchResults = resultsOrder
          .map(({ title, to, itemLink, tag }) => {
            const moduleDataPhp = phpSearchResult.find(({ layerTag }) => layerTag === tag)
            const moduleDataApi = moduleDataPhp ? null : apiSearchResult.find(({ layerTag }) => layerTag === tag)
            const moduleDataServiceArea = moduleDataApi || moduleDataPhp ? null : serviceAreaSearchResult.find(({ layerTag }) => layerTag === tag)
            const moduleData = moduleDataPhp || moduleDataApi || moduleDataServiceArea

            if (tag === 'SERVICE_DISTRICTS') {
              console.log(moduleData, { moduleDataPhp, moduleDataApi, moduleDataServiceArea })
            }
            const isPhp = Boolean(moduleDataPhp)
            const isApi = Boolean(moduleDataApi)
            const isServiceArea = Boolean(moduleDataServiceArea)

            if (moduleData) {
              const { id, items, count, layer, layerTag } = moduleData

              let linkQuery

              if (layerTag === 'MEDICINE') {
                linkQuery = /врачи/.test(layer) ? 'district' : 'institution'
              }

              const modifiedItems = modifySearchItems({ items, itemLink, to, linkQuery })
                .filter((item, index) => index < 2)

              if (items.length) {
                return {
                  title,
                  count,
                  items: modifiedItems,
                  loadMore: () => {
                    if (isPhp) {
                      return loadMorePhp({ search, layerId: id })
                        .then(({ items }) => modifySearchItems({ items, itemLink, to }))
                    }
                    if (isApi) {
                      return loadMoreApi({ search, layerId: id })
                        .then(({ items }) => modifySearchItems({ items, itemLink, to }))
                    }
                    if (isServiceArea) {
                      return loadMoreServiceArea({ search, layerId: id })
                        .then(({ items }) => {
                          return modifiedItems.concat(modifySearchItems({ items, itemLink, to }))
                        })
                    }
                  },
                }
              }
            }

            return null
          })
          .filter(Boolean)

        cache[search] = searchResults

        if (searchResults.length) {
          setState({ view: 'fetched', searchResults })
        }
        else {
          setState({ view: 'empty', searchResults: [] })
        }
      })
  }, [ searchApi, searchPhp, searchServiceArea, loadMoreApi, loadMorePhp, loadMoreServiceArea ])

  const handleButtonClick = useCallback(async (event) => {
    event.preventDefault()

    const { values } = await form.submit()
    const { search } = values

    const storageSearchItems = [ search, ...prevSearchItems ].filter((item, index) => index < 6)

    localStorage.setItem('searchItems', storageSearchItems)

    handleSearch(search)
  }, [ form, prevSearchItems, handleSearch ])

  const total = useMemo(() => {
    if (searchResults) {
      return searchResults.reduce((result, { count }) => result + count, 0)
    }

    return 0
  }, [ searchResults ])

  return {
    form,
    view,
    total,
    searchResults,
    prevSearchItems,
    handleSearch,
    handleButtonClick,
  }
}


export default useSearch
