import React from 'react'

import {
  CommonModal,
  LoginModal,
  SearchModal,
  SupportModal,
  FullScreenModal,
  SubmitFailedModal,
  SubmitSucceededModal,
  ConfirmModal,
} from 'compositions/modals'


const Modals = () => (
  <>
    <CommonModal />
    <LoginModal />
    <SearchModal />
    <SupportModal />
    <FullScreenModal />
    <SubmitFailedModal />
    <SubmitSucceededModal />
    <ConfirmModal />
  </>
)


export default Modals
