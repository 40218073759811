import React from 'react'
import links from 'links'

import { Text } from 'components/dataDisplay'
import { Button, ActionButton } from 'components/inputs'


type ApplyAgreementProps = {
  className?: string
  handleApplyAgreementClick: () => void
}

const ApplyAgreement: React.FC<ApplyAgreementProps> = (props) => {
  const { className, handleApplyAgreementClick } = props

  return (
    <>
      <Text
        message="Правила сервиса"
        size="h24"
      />
      <Text
        className="mt-8px opacity-72"
        message="Для продолжения регистрации, необходимо согласие с правилами сервиса"
        size="t16-24"
      />
      <ActionButton
        className="mt-16px"
        title="Пользовательское соглашение"
        icon="file/file_16"
        color="rocky"
        toTab={links.privacy}
      />
      <Button
        className="mt-24px"
        title="Прочитано, соглашаюсь"
        icon="main/avatar_16"
        size={40}
        onClick={handleApplyAgreementClick}
        fullWidth
        style="primary"
      />
    </>
  )
}

export default ApplyAgreement