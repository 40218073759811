import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { Field, useFieldState } from 'formular'

import { Href } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase, Form, Input } from 'components/inputs'

import s from './SearchInput.scss'


type SearchInputProps = {
  className?: string
  field: Field<string>
  onButtonClick: () => void
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { className, field, onButtonClick } = props

  const { isMobile } = useDevice()

  const [ isFocused, setFocused ] = useState(false)

  const { value } = useFieldState(field)

  useEffect(() => {
    const input = document.getElementById('searchInput')

    if (input) {
      setTimeout(() => input.focus(), 0)
    }
  }, [])

  return (
    <Form
      className={className}
      disabled={!value}
      onSubmit={onButtonClick}
    >
      <div className={cx(s.container, 'flex radius-32 overflow-hidden')}>
        <Input
          id="searchInput"
          autoFocus={!value}
          className={cx(s.input, 'flex-1', {
            [s.filled]: Boolean(value),
            [s.focused]: isFocused,
          })}
          field={field}
          label=""
          icon="main/search_20"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {
          Boolean(value) && (
            <Href
              className="pointer mr-12px flex items-center"
              onClick={() => field.set('')} // TODO add autofocus
            >
              <Icon
                className="opacity-48"
                name="action/close_16"
                color="rocky"
              />
            </Href>
          )
        }
        <ButtonBase
          className={cx(s.button, 'radius-32')}
          disabled={!value}
          type="submit"
        >
          <Text
            className={isMobile ? 'px-32px' : 'px-48px'}
            size="c16"
            message="Найти"
            color="white"
          />
        </ButtonBase>
      </div>
    </Form>
  )
}


export default SearchInput
