import React, { useEffect } from 'react'
import config from 'config'

import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { useUser } from 'models/user'

import { Modal } from 'components/feedback'
import { LoginForm } from 'compositions/form'


type LoginModalProps = {
  title?: string
  text?: string
  redirectLink?: string
  callback?: () => void
}

const LoginModal: React.FC<LoginModalProps & ModalVisibilityProps> = (props) => {
  const { title, text, redirectLink, callback, closeModal } = props
  const { handleLogin } = useUser()

  useEffect(() => {
    if (!config.isLegacyAuth) {
      handleLogin()
    }
  }, [ handleLogin ])

  return config.isLegacyAuth && (
    <Modal
      title=''
      color="white"
      closeModal={closeModal}
    >
      <LoginForm
        title={title}
        text={text}
        redirectLink={redirectLink}
        callback={callback}
      />
    </Modal>
  )
}

export const openLoginModal = (props?: LoginModalProps) => openModal('LoginModal', props)

export default modalVisibility('LoginModal', LoginModal)
