import { useField, useForm } from 'formular'
import { required, email, isPhoneNumber } from 'helpers/validators'
import { useMutation } from 'hooks'
import links from 'links'
import { closeAllModals } from 'modal'
import { submitAndCheckForErrors } from 'models/api'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'router'

import { arePasswordsEqual } from '../helpers/validators'


export type LoginModalViews = 'enterCreds' | 'applyAgreement' | 'setPassword' | 'registrationSuccess'
| 'registration' | 'loginSuccess' | 'loginError'
| 'recoverPassword' | 'recoverPasswordSuccess'

const useLoginModal = () => {
  const [ view, setView ] = useState<LoginModalViews>('enterCreds')
  const [ socialError, setSocialError ] = useState<LoginModalViews>(null)
  // const [ loginTryCount, setLoginTryCount ] = useState(0)
  const history = useHistory()

  const newPasswordRef = useRef<string>()

  const loginForm = useForm<{
    email: string
    password: string
  }>({
    fields: {
      email: [ required ],
      password: [ required ],
    },
  })


  const emailField = useField<string>({ validate: [ required ], value: '' })
  const passwordField = useField<string>({ validate: [ required ], value: '' })

  const newNameField = useField<string>({ validate: [ required ], value: '' })
  const newEmailField = useField<string>({ validate: [ required, email ], value: '' })
  const newPhoneField = useField<string>({ validate: [ isPhoneNumber ], value: '' })
  const newPasswordField = useField<string>({ validate: [ required ], value: '' })
  const newPasswordRepeatField = useField<string>({ validate: [ required, arePasswordsEqual(newPasswordRef) ] })

  useEffect(() => {
    newPasswordField.on('change', (value) => {
      newPasswordRef.current = value
      if (newPasswordField.state.value) {
        newPasswordRepeatField.validate()
      }
    })
  }, [ newPasswordField, newPasswordRepeatField, passwordField ])

  const [ submitLogin, { isSubmitting: isLoginSubmitting } ] = useMutation<UserModel.UserApiData>({
    url: '/auth/rest/',
  })

  const loginTryCount = useRef(0)

  const handleLogin = useCallback(async (event) => {
    event.preventDefault()

    const { values, errors } = await loginForm.submit()

    if (errors) return

    if (loginTryCount.current < 9) {
      const { data: { status } } = await submitLogin({
        login: values.email,
        password: values.password,
      })

      if (status > 0) {
        setView('loginSuccess')
      }
      else if (status === -5) {
        loginForm.fields.password.setError('Неверные учетные данные')
        loginTryCount.current = loginTryCount.current + 1
        // setLoginTryCount((prev) => prev + 1)
      }
      else if (status === -8) {
        loginForm.fields.password.setError('Вход с данного ip-адреса или данным методом запрещён')
        // setLoginTryCount((prev) => prev + 1)
        loginTryCount.current = loginTryCount.current + 1
      }
      else if (status === -2) {
        loginForm.fields.password.setError('Данный пользователь не существует')
        // setLoginTryCount((prev) => prev + 1)
        loginTryCount.current = loginTryCount.current + 1
      }
      else if (status === -1) {
        loginForm.fields.password.setError('Неизвестная ошибка')
      }
      else if (status === -10) {
        loginForm.fields.password.setError('Пользователь деактивирован')
        loginTryCount.current = loginTryCount.current + 1
      }
    }
    else {
      setView('loginError')
    }
  }, [ loginForm, submitLogin ]) //loginTryCount

  const [ submitRegistration ] = useMutation<{ status: number, description?: string }>({
    url: '/auth/rest/user/new',
  })

  const handleRegistrationSubmit = useCallback(async (event) => {
    event.preventDefault()
    //@ts-ignore
    const emailError = await newEmailField.validate()
    //@ts-ignore
    const passwordError = await newPasswordField.validate()
    //@ts-ignore
    const phoneError = await newPhoneField.validate()

    if (emailError || passwordError || phoneError) return

    const body = {
      email: newEmailField.state.value,
      password: newPasswordField.state.value,
      firstName: newNameField.state.value,
      phone: newPhoneField.state.value,
    }

    submitRegistration(body)
      .then(({ data }) => {
        const { status, description } = data

        if (status > 0) {
          setView('registrationSuccess')
        }
        else {
          newEmailField.setState({ isValid: false, error: description })
        }
      })
  }, [ newEmailField, newNameField.state.value, newPasswordField, newPhoneField, submitRegistration ])

  const [ submitRecoverPassword ] = useMutation({
    url: '/auth/rest/psw/init-update',
    method: 'POST',
  })

  const handleRecoverPasswordSubmit = useCallback((email) => {
    submitAndCheckForErrors({
      job: () => submitRecoverPassword({ email }),
      succeededTitle: 'Письмо с инструкциями для сброса пароля отправлено',
      succeededRedirectLink: links.home,
      succeededCallback: () => closeAllModals(),
      failedTitle: 'Ошибка',
    })
  }, [ submitRecoverPassword ])

  const handlePasswordRecoveryClick = useCallback(() => { // TODO refactor this, pass setView
    setView('recoverPassword')
  }, [])
  const handleLoginRetryClick = useCallback(() => {
    setView('enterCreds')
  }, [])
  const handleRegistrationClick = useCallback(() => {
    setView('applyAgreement')
  }, [])
  const handleApplyAgreementClick = useCallback(() => {
    setView('registration')
  }, [])

  useEffect(() => {
    if (view === 'loginError' && socialError) {
      return () => {
        setSocialError(null)
      }
    }
  }, [ view, socialError ])

  const onError = (error) => {
    if (error) {
      setSocialError(error)
    }
    setView('loginError')
  }

  const onSuccess = () => setView('loginSuccess')

  return {
    loginForm, handleLogin,
    socialError,
    view, emailField, passwordField, newEmailField, newPasswordField, newPasswordRepeatField,
    newNameField, newPhoneField, isLoginSubmitting,
    handlePasswordRecoveryClick, handleRegistrationClick, handleApplyAgreementClick,
    handleLoginRetryClick, handleRegistrationSubmit, handleRecoverPasswordSubmit,
    onError,
    onSuccess,
  }
}

export default useLoginModal