import React from 'react'

import { Icon, Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'


type LoginErrorProps = {
  error?: string
  handleButtonClick: () => void
}

const LoginError: React.FC<LoginErrorProps> = (props) => {
  const { error, handleButtonClick } = props

  return (
    <>
      <Icon
        name="main/block_32"
        color="rocky"
      />
      <Text
        className="mt-24px"
        message="Ошибка входа"
        size="h24"
      />
      {
        Boolean(error) && (
          <Text
            className="mt-8px opacity-72"
            message={error}
            size="t16-24"
          />
        )
      }
      <Text
        className="mt-8px opacity-72"
        message="Повторите попытку или попробуйте другой способ входа"
        size="t16-24"
      />
      <Button
        className="mt-24px"
        title="Другие способы входа"
        size={40}
        style="secondary"
        onClick={handleButtonClick}
        fullWidth
      />
    </>
  )
}

export default LoginError