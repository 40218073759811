import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { array } from 'helpers'
import { useDevice } from 'device'

import { Href } from 'components/navigation'
import { Carousel, Icon, Image, Text } from 'components/dataDisplay'
import { Bone, WidthContainerCompensator } from 'components/layout'

import noPhotoImg from 'components/dataDisplay/Image/images/noPhoto72.svg'

import { useCarouselAppeals } from './util'

import s from './OtherAppealsSection.scss'


type OtherAppealsSectionProps = {
  className?: string
  limit?: number
  onClick?: () => void
}

const OtherAppealsSection: React.FC<OtherAppealsSectionProps> = ({ className, limit, onClick }) => {
  const { carouselList, isCarouselListFetching } = useCarouselAppeals()

  const { isMobile, isTablet } = useDevice()

  const itemsLimit = limit || isTablet ? 2 : 3
  const itemsList = useMemo(() => {
    if (limit) {
      return carouselList.filter((item, index) => index < limit)
    }

    return carouselList
  }, [ limit, carouselList ])

  const childNodes = isCarouselListFetching ? (
    array.range(1, itemsLimit).map((count, index) => (
      <Bone
        key={index}
        className={cx('radius-16 flex-1', index ? 'ml-32px' : null)}
        h={286}
      />
    ))
  ) : (
    itemsList.map(({ id, title, image, to, address, categoryId, type }, index) => (
      <Href
        key={id}
        className={cx('pointer radius-16 flex-1 overflow-hidden bg-rush block', {
          'ml-32px': index && !isMobile,
        })}
        to={to}
        onClick={onClick}
      >
        <Image
          className="w-full bg-arrival"
          src={image || noPhotoImg}
          height="146"
          tag="div"
          fallback={noPhotoImg}
        />
        <div className="pt-12px pb-20px px-20px">
          <Text
            message={type}
            size="s13-r"
            color="alien"
          />
          <Text
            className="mt-8px overflow-ellipsis webkit-box"
            message={title}
            size="h16-20"
            color="titanic"
          />
          {/*<Text*/}
          {/*  className="mt-4px overflow-ellipsis-2 opacity-48"*/}
          {/*  message={description}*/}
          {/*  size="s13-r"*/}
          {/*  color="titanic"*/}
          {/*/>*/}
          <div className="mt-8px flex items-start opacity-72">
            <Icon className="flex-none" name="map/geopoint_16" color="titanic" />
            <Text
              className="ml-8px"
              message={address}
              size="s13-r"
              color="titanic"
            />
          </div>
        </div>
      </Href>
    ))
  )

  const [ itemWidth, setItemWidth ] = useState(271)

  useEffect(() => {
    if (isMobile) {
      const getItemWidth = () => {
        const itemWidth = window.innerWidth - 48 + 16

        setItemWidth(itemWidth)
      }

      getItemWidth()

      window.addEventListener('resize', getItemWidth)

      return () => {
        window.removeEventListener('resize', getItemWidth)
      }
    }
  }, [ isMobile ])

  if (isMobile) {
    return (
      <WidthContainerCompensator>
        <Carousel
          className={cx(className, s.carousel)}
          itemWidth={itemWidth}
          exactWidth
        >
          {childNodes}
        </Carousel>
      </WidthContainerCompensator>
    )
  }
  return (
    <div className={cx(className, 'flex')}>
      {childNodes}
    </div>
  )
}


export default OtherAppealsSection
