import React from 'react'
import links from 'links'

import { Text } from 'components/dataDisplay'

import { HeaderButton } from 'components/inputs'

import OtherAppealsSection from 'layers/appeals/pages/components/OtherAppealsSection/OtherAppealsSection'


type EmptyViewProps = {
  className?: string
  closeModal: () => void
}

const EmptyView: React.FC<EmptyViewProps> = ({ className, closeModal }) => {

  return (
    <div className={className}>
      <div className="text-center">
        <Text
          message="По вашему запросу ничего не нашлось"
          size="t16-20"
          color="titanic"
        />
        <Text
          className="opacity-48 mt-4px"
          message="Попробуйте изменить запрос"
          size="t16-20"
          color="titanic"
        />
      </div>
      <div className="mt-80px">
        <HeaderButton
          title="Обращения жителей"
          size="h24"
          to={links.appeals.root}
        />
        <OtherAppealsSection
          className="mt-24px"
          limit={2}
          onClick={closeModal}
        />
      </div>
    </div>
  )
}


export default EmptyView
