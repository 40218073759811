import React from 'react'
import { DeviceProvider } from 'device'
import { Router } from 'router'
import { QueryProvider } from 'hooks/query/context'
import { HelmetProvider } from 'react-helmet-async'

import { AuthProvider } from 'containers/AuthProvider'

import CommonData from './CommonData'


const CommonDataChunk: React.FC = () => (
  <HelmetProvider>
    <DeviceProvider value={window.__DEVICE__}>
      <QueryProvider value={window.__QUERY_STATE__}>
        <AuthProvider>
          <Router>
            <CommonData />
          </Router>
        </AuthProvider>
      </QueryProvider>
    </DeviceProvider>
  </HelmetProvider>
)


export default CommonDataChunk
