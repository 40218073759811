import React from 'react'
import links from 'links'
import cx from 'classnames'
import { closeAllModals } from 'modal'

import useLoginModal from './util/useLoginModal'
import { ApplyAgreement, EnterCreds, LoginError, LoginSuccess, RecoverPassword, Registration,
  RegistrationSuccess } from './components'


type LoginFormProps = {
  className?: string
  title?: string
  text?: string
  redirectLink?: string
  callback?: () => void
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { className, title, text, redirectLink = links.home, callback } = props

  const {
    loginForm, handleLogin,
    socialError,
    view, newEmailField, newPasswordField, newPasswordRepeatField,
    newNameField, newPhoneField, isLoginSubmitting,
    handlePasswordRecoveryClick, handleLoginRetryClick, handleRegistrationClick,
    handleApplyAgreementClick, handleRegistrationSubmit,
    onError, onSuccess, handleRecoverPasswordSubmit,
  } = useLoginModal()

  return (
    <div className={cx(className, 'px-24px')}>
      {
        view === 'enterCreds' && (
          <EnterCreds
            title={title}
            text={text}
            form={loginForm}
            isLoginSubmitting={isLoginSubmitting}
            onSubmit={handleLogin}
            handleRegistrationClick={handleRegistrationClick}
            handlePasswordRecoveryClick={handlePasswordRecoveryClick}
            onError={onError}
            onSuccess={onSuccess}
          />
        )
      }
      {
        view === 'applyAgreement' && (
          <ApplyAgreement
            handleApplyAgreementClick={handleApplyAgreementClick}
          />
        )
      }
      {
        view === 'registration' && (
          <Registration
            newNameField={newNameField}
            newEmailField={newEmailField}
            newPasswordField={newPasswordField}
            newPhoneField={newPhoneField}
            newPasswordRepeatField={newPasswordRepeatField}
            handleRegistrationSubmit={handleRegistrationSubmit}
          />
        )
      }
      {
        view === 'registrationSuccess' && (
          <RegistrationSuccess
            closeModal={closeAllModals}
            emailField={newEmailField}
          />
        )
      }
      {
        view === 'loginSuccess' && (
          <LoginSuccess
            redirectLink={redirectLink}
            closeLoginModal={closeAllModals}
            callback={callback}
          />
        )
      }
      {
        view === 'loginError' && (
          <LoginError
            error={socialError}
            handleButtonClick={handleLoginRetryClick}
          />
        )
      }
      {
        view === 'recoverPassword' && (
          <RecoverPassword
            handleRecoverPasswordSubmit={handleRecoverPasswordSubmit}
          />
        )
      }
    </div>
  )
}

export default LoginForm