// fetch info about public modules included in current license
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import config from 'config'


export type LicenseApiData = {
  tag: UserModel.PublicModuleTag
  title: string
  // customTitle?: string // legacy
  publicAccess: boolean
  active: boolean
  oldId: number
  customSettings: {
    publicAccess: boolean
    title: string
  }
}[]

const usePublicModules = (skip?: boolean) => {
  const options = useMemo(() => {
    const pathname = config.isLegacyAuth ? '/auth/rest/access/public' : '/auth/rest/public/access/public'

    return ({
      url: pathname,
      skip,
    })
  }, [ skip ])

  const { data, isFetching, errors } = useQuery<LicenseApiData>(options)

  const availableModuleTags = useMemo(() => {
    if (data) {
      return data.map(({ tag }) => tag)
    }
  }, [ data ])

  return {
    modules: data || [],
    availableModuleTags,
    isFetching,
  }
}

export default usePublicModules