import { useCallback } from 'react'
import { useMutation } from 'hooks'
import { Field } from 'formular'
import config from 'config'


type Input = {
  loginField: Field<string>
  emailField: Field<string>
  passwordField: Field<string>
}

type ApiResponse = {
  imPermissibilityReason: string
  permissible: boolean
}

const useCheckPassword = ({ loginField, emailField, passwordField }: Input) => {
  const [ submit, { isSubmitting } ] = useMutation<ApiResponse>({
    url: '/auth/rest/psw/check-permissible',
  })

  const handleCheckPassword = useCallback(async () => {
    if (!config.isLegacyAuth) {
      return
    }

    const login = loginField.state.value
    const email = emailField.state.value
    const password = passwordField.state.value

    const { data, cacheStorage } = await submit({
      url: '/auth/rest/psw/check-permissible',
      body: {
        login,
        email,
        password,
      },
      method: 'POST',
    })

    if (data) {
      if (!data.permissible && data?.imPermissibilityReason) {
        passwordField.setError(data.imPermissibilityReason)
      }
      else {
        passwordField.setError(null)
        passwordField.validate()
      }
    }
  }, [
    loginField,
    emailField,
    passwordField,
    submit,
  ])

  return {
    isSubmitting,
    handleCheckPassword,
  }
}


export default useCheckPassword
