import dayjs from 'date'
import links, { getLinkWithParams } from 'links'


type ModifySearchItemsProps = {
  items: SearchModal.SearchData['items']
  itemLink?: string
  linkQuery?: string
  to: string
}

const modifyItemLink = ({ itemLink, itemId, selectedItemId, linkQuery }) => {
  if (itemLink === links.medicine.item) {
    return `${itemLink}?id=${itemId || selectedItemId}${linkQuery ? `&type=${linkQuery}` : ''}`
  }
  if (itemLink === links.serviceArea.item) {
    return `${itemLink}?id=${itemId || selectedItemId}`
  }

  return getLinkWithParams(itemLink, { itemId, selectedItemId })
}

const modifySearchItems = ({ items, itemLink, to, linkQuery }: ModifySearchItemsProps): SearchModal.SearchResult['items'] => {
  return items.map((item) => {
    const isRelativeImage = item.image && !/^(\/|https?:\/\/)/.test(item.image)

    return {
      ...item,
      image: isRelativeImage ? `/${item.image}` : item.image,
      date: item.date ? dayjs(item.date, 'YYYY-MM-DD').format('DD MMMM YYYY') : '',
      to: itemLink ? modifyItemLink({ itemLink, itemId: item.id, selectedItemId: item.id, linkQuery }) : to,
    }
  })
}


export default modifySearchItems
