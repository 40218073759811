import React from 'react'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Modal } from 'components/feedback'
import SupportForm from 'compositions/form/SupportForm/SupportForm'


const SupportModal: React.FC<ModalVisibilityProps & { appealCategoryId?: number }> = (props) => {
  const { appealCategoryId, closeModal } = props

  return (
    <>
      <Modal
        title=""
        closeModal={closeModal}
      >
        <SupportForm appealCategoryId={appealCategoryId} />
      </Modal>
    </>
  )
}

export const openSupportModal = (props) => openModal('supportModal', props)

export default modalVisibility('supportModal', SupportModal)