import { useMemo } from 'react'
import { useQuery } from 'hooks'
import config from 'config'


const usePasswordPolicies = () => {
  const queryOptions = useMemo(() => ({
    url: '/auth/rest/psw/policies',
    skip: !config.isLegacyAuth,
    json: false,
  }), [])

  const { data, errors, isFetching } = useQuery<string>(queryOptions)

  console.log({ data, errors })
  return {
    policies: (data || '').replace(/\n/g, '<br />'),
    isFetching,
  }
}


export default usePasswordPolicies
