import React, { useCallback, useEffect, useRef } from 'react'
import { closeAllModals, modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { useHistory } from 'router'

import { Modal } from 'components/feedback'
import { Icon, Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'


interface SubmitSucceededModalProps {
  title?: string
  subTitle?: string
  text?: string
  buttonTitle?: string
  redirectLink?: string
  noAutoClose?: boolean // do not autoclose after 5 sec
  callback?: () => void // function to call before redirect(handle cache i.e.)
}

const SubmitSucceededModal: React.FC<SubmitSucceededModalProps & ModalVisibilityProps> = (props) => {
  const { title = 'Изменения успешно внесены', subTitle, text, buttonTitle = 'Понятно', redirectLink, noAutoClose = false,
    callback, closeModal } = props

  const timeoutRef = useRef<NodeJS.Timeout>()

  const history = useHistory()

  const handleModalClose = useCallback(() => {
    closeModal()

    if (typeof callback === 'function') {
      callback()
    }

    if (redirectLink) {
      history.push(redirectLink)
    }
  }, [ callback, closeModal, history, redirectLink ])

  useEffect(() => {
    if (!noAutoClose) {
      timeoutRef.current = setTimeout(
        handleModalClose, 5000)

      return () => {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [ closeModal, handleModalClose, noAutoClose ])

  const handleOkClick = useCallback(() => {
    clearTimeout(timeoutRef.current)
    handleModalClose()
  }, [ handleModalClose ])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleOkClick()
      }
    }
    document.addEventListener('keypress', handleKeyPress)

    return () => document.removeEventListener('keypress', handleKeyPress)
  }, [ handleOkClick ])

  return (
    <Modal
      title=""
      closeModal={closeModal}
    >
      <div className="flex flex-col justify-center">
        <Icon
          name="action/selected_56"
          color="matrix"
        />
        <Text
          className="mt-32px"
          message={title}
          size="h32"
        />
        {
          subTitle && (
            <Text
              className="mt-16px"
              message={subTitle}
              size="h20-24"
            />
          )
        }
        {
          text && (
            <Text
              className="mt-16px opacity-48"
              message={text}
              size="t16-24"
            />
          )
        }
        <Button
          className="mt-32px"
          title={buttonTitle}
          style="secondary"
          onClick={handleOkClick}
          fullWidth
          size={40}
        />
      </div>
    </Modal>
  )
}

export const openSubmitSucceededModal = (props: SubmitSucceededModalProps) => openModal('submitSucceededModal', props)


export default modalVisibility('submitSucceededModal', SubmitSucceededModal)